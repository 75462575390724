













































































































































































































































































































































































import {Component, Prop, VModel, Watch} from 'vue-property-decorator';
import ErrorMessageHandlerMixin from '@/helper/ErrorMessageHandler.mixin';
import {mixins} from 'vue-class-component';
import {namespace} from 'vuex-class';
import {validationMixin} from 'vuelidate';
import {
  email,
  maxLength,
  minLength,
  numeric,
  required,
  requiredIf,
} from 'vuelidate/lib/validators';
import User from '@/models/User';
import UserRole from '@/models/user-attributes/UserRole';
import CleanTime from '@/models/CleanTime';
import Gender from '@/models/user-attributes/Gender';
import EmploymentType from '@/models/user-attributes/EmploymentType';
import {EmploymentTypes} from '@/misc/user-attributes/EmploymentTypes';
import PaymentType from '@/models/user-attributes/PaymentType';
import BillingDelivery from '@/models/user-attributes/BillingDelivery';
import Denomination from '@/models/user-attributes/Denomination';
import moment from 'moment';
import {cleanTimeStoreActions} from '@/stores/cleanTime.store';
import {VersionControl} from '@/misc/VersionControl';
import MapRepository from '@/api/repositories/MapRepository';
import {RepositoryFactory} from '@/api/RepositoryFactory';
import {Permission} from '@/misc/enums/permission.enum';

const UserStore = namespace('user');
const CleanTimeStore = namespace('cleanTime');
const mapRepository: MapRepository = RepositoryFactory.get('map');


/**
 * Component to create or edit a user entity
 */
@Component({
  mixins: [validationMixin],
  validations: {
    userCopy: {
      role: {
        required,
      },
      email: {
        // TODO change 'employee' to non hardcode
        required: requiredIf((vm) => {
          // required if at create the hasNoEmail prop is false
          const create = !vm.id && vm.userHasNoEmail === false;
          // required if at edit no personnel number is set
          const edit = vm.id && !vm.personnelNumber;
          // required if role is not employee
          return create || edit;
        }),
        email,
      },
      firstName: {
        required,
      },
      lastName: {
        required,
      },
      personnelNumber: {
        // id and email check for edit user and userHasNoEmail and role for creation
        // if user has an id it is in edit mode. Then check the email. If no email is given, the employee has no email
        // at creation, then the personnel number must be required
        required: requiredIf((vm) => {
          const editCheck = vm.id && !vm.email;
          const creationCheck = !vm.id && vm.userHasNoEmail;
          return editCheck || creationCheck;
        }),
      },
      address: {
        street: {required},
        houseNo: {required, maxLength: maxLength(15)},
        postalCode: {required, numeric, maxLength: maxLength(5)},
        city: {required},
      },
      pensionInsurance: {
        required: requiredIf((vm) => {
          return vm.employmentType && vm.employmentType.name === EmploymentTypes.miniJob;
        }),
      },
      phone: {
        required,
        minLength: minLength(3),
      },
    },
  },
  components: {
    MenuWithPicker: () => import(
      '@/components/shared/MenuWithPicker.component.vue'),
    RJTextField: () => import(
      '@/components/shared/custom-vuetify/RJTextField.vue'),
    RJSelect: () => import(
      '@/components/shared/custom-vuetify/RJSelect.vue'),
    SideCardComponent: () => import(
      '@/components/shared/SideCard/SideCard.component.vue'),
    WorkingTimeComponent: () => import(
      '@/components/shared/WorkingTime.component.vue'),
  },
})
export default class UserManageContentComponent extends mixins(ErrorMessageHandlerMixin) {

  @Prop({default: () => new User()})
  public user!: User;

  @Prop({default: () => true})
  public gotoUsersOverview!: boolean;

  @VModel({default: () => false})
  public checkChanges?: boolean;

  public userCopy: User = new User();

  /**
   * Bool to reset the workingTimeComponent. Otherwise the very first workingTimes will be saved and displayed for every user
   * @private
   */
  private resetWorkingTimes: boolean = false;

  public submitted: boolean = false;
  public maxHoursEmpty: boolean = false;
  public personalNumberInUse: boolean = false;
  public role: UserRole | null = null;
  public oneTimePassword: string = '';

  private availableAtCleanTimes: CleanTime[] = [];
  private cleanTimesToDelete: CleanTime[] = [];
  private resetPassword: boolean = false;

  private invalidWorkingTimes: boolean = false;

  @CleanTimeStore.Action(cleanTimeStoreActions.CREATE_CLEAN_TIME_ACTION)
  public createCleanTimeAction!: (cleanTime: CleanTime) => Promise<CleanTime>;
  @CleanTimeStore.Action(cleanTimeStoreActions.UPDATE_CLEAN_TIME_ACTION)
  public updateCleanTimeAction!: (payload: { cleanTime: CleanTime, splitAtDate: string }) => Promise<CleanTime>;
  @CleanTimeStore.Action(cleanTimeStoreActions.DELETE_CLEAN_TIME_ACTION)
  public deleteCleanTimeAction!: (cleanTimeId: string) => Promise<CleanTime>;
  @UserStore.Action('createUserAction')
  private createUserAction!: (user: User) => Promise<User>;
  @UserStore.Action('editUserAction')
  private editUserAction!: (user: User) => Promise<User>;
  @UserStore.Mutation('storeActiveUser')
  private activeUserMutation!: (user: User) => any;
  @UserStore.Action('loadUserRolesAction')
  private loadRolesAction!: (payload: { companyId: string }) => Promise<void>;
  @UserStore.Action('loadUsersAttributesAction')
  private loadUsersAttributesAction!: () => Promise<void>;

  @UserStore.Getter('roles')
  private _roles!: UserRole[];

  /**
   * get UserRoles with translated names
   */
  get roles(): Array<{ text: any, value: UserRole }> {
    return this._roles.filter((item) => !item.isSuperAdmin).map((entry) => {
      return {text: entry.name, value: entry};
    });
  }

  @UserStore.Getter('employmentTypes')
  private _employmentTypes!: EmploymentType[];

  get employmentTypes(): Array<{ text: any, value: EmploymentType }> {
    return this._employmentTypes.map((entry) => {
      const elmText = this.$t(`USER_DETAIL.EMPLOYMENT_TYPE.${entry.name.toUpperCase()}`) ?
        this.$t(`USER_DETAIL.EMPLOYMENT_TYPE.${entry.name.toUpperCase()}`) : entry.name;
      return {text: elmText, value: entry};
    });
  }

  @UserStore.Getter('denominations')
  private _denominations!: Denomination[];

  get denominations(): Array<{ text: any, value: Denomination }> {
    return this._denominations.map((entry) => {
      const elmText = this.$t(`USER_DETAIL.DENOMINATION.${entry.name.toUpperCase()}`) ?
        this.$t(`USER_DETAIL.DENOMINATION.${entry.name.toUpperCase()}`) : entry.name;
      return {text: elmText, value: entry};
    });
  }

  @UserStore.Getter('paymentTypes')
  private _paymentTypes!: PaymentType[];

  get paymentTypes(): Array<{ text: any, value: PaymentType }> {
    return this._paymentTypes.map((entry) => {
      const elmText = this.$t(`USER_DETAIL.PAYMENT_TYPE.${entry.name.toUpperCase()}`) ?
        this.$t(`USER_DETAIL.PAYMENT_TYPE.${entry.name.toUpperCase()}`) : entry.name;
      return {text: elmText, value: entry};
    });
  }

  @UserStore.Getter('gender')
  private _gender!: Gender[];

  get gender(): Array<{ text: any, value: Gender }> {
    return this._gender.map((entry) => {
      const elmText = this.$t(`USER_DETAIL.GENDER.${entry.name.toUpperCase()}`) ?
        this.$t(`USER_DETAIL.GENDER.${entry.name.toUpperCase()}`) : entry.name;
      return {text: elmText, value: entry};
    });
  }

  @UserStore.Getter('billingDeliveries')
  private _billingDeliveries!: BillingDelivery[];

  get billingDeliveries(): Array<{ text: any, value: BillingDelivery }> {
    return this._billingDeliveries.map((entry) => {
      const elmText = this.$t(`USER_DETAIL.BILLING_DELIVERY.${entry.name.toUpperCase()}`) ?
        this.$t(`USER_DETAIL.BILLING_DELIVERY.${entry.name.toUpperCase()}`) : entry.name;
      return {text: elmText, value: entry};
    });
  }

  get paymentIntervals(): Array<{ text: any, value: number }> {
    return [
      {text: this.$t('USER_DETAIL.PAYMENT_INTERVAL.MONTHLY'), value: 1},
      {text: this.$t('USER_DETAIL.PAYMENT_INTERVAL.QUARTER'), value: 3},
    ];
  }

  get residence(): Array<{ text: any, value: boolean }> {
    return [
      {text: this.$t('USER_DETAIL.NATIONALITY.EU_CITIZEN'), value: true},
      {text: this.$t('USER_DETAIL.NATIONALITY.NO_EU_CITIZEN'), value: false},
    ];
  }

  get pensionInsurances(): Array<{ text: any, value: boolean }> {
    return [
      {text: this.$t('USER_DETAIL.PENSION_INSURANCE.FREE'), value: true},
      {text: this.$t('USER_DETAIL.PENSION_INSURANCE.COMPULSORY'), value: false},
    ];
  }

  get roleErrorMessage(): string {
    if (this.submitted && !this.roles.map((role) => role.value.name).includes((this.role as UserRole).name)) {
      return this.$t('ERROR_MESSAGES.REQUIRED').toString();
    }
    return '';
  }

  get isMiniJobber() {
    return this.userCopy.employmentType && this.userCopy.employmentType.name === EmploymentTypes.miniJob;
  }

  /**
   * This attribute needs to be inverted for frontend
   * @private
   */
  private get locationCheck() {
    return !this.userCopy.skipLocationCheckOnWorkSessions;
  }

  private set locationCheck(value: boolean) {
    this.userCopy.skipLocationCheckOnWorkSessions = !value;
  }

  private createPassword() {
    this.oneTimePassword = Math.random().toString(36).substr(2, 8);
  }

  private get showResetBtn() {
    return this.userCopy.id && this.hasNoEmail() && !this.userCopy.email;
  }

  @Watch('user', {immediate: true})
  public onUserChange() {
    this.submitted = false;
    this.userCopy = User.parseFromObject(this.user.parseToObject());
    this.role = UserRole.parseFromObject(this.userCopy.role as UserRole);
    this.searchString = this.userCopy.address?.street!;
    this.$v.$reset();
    // default value
    this.availableAtCleanTimes = [];
    this.resetWorkingTimes = true;
    if (this.userCopy.availableAtCleanTimes) {
      this.availableAtCleanTimes = CleanTime.parseFromArray(this.userCopy.availableAtCleanTimes) as CleanTime[];
    }
    this.$nextTick(() => {
      this.resetWorkingTimes = false;
    });
  }

  @Watch('checkChanges')
  public onCloseSideCard() {
    if (this.checkChanges) {
      VersionControl.compare(this.user, this.userCopy) ? this.$emit('exit') : this.$emit('warn-dialog');
    }
  }

  public isManager(): boolean {
    if (!this.userCopy.role) {
      return false;
    }
    return this.userCopy.role!.isTenantAdmin;
  }

  public hasNoEmail() {
    return !this.userCopy.email;
  }

  /**
   * Tells the parent to close the modal window
   */
  public cancelButtonClicked() {
    this.checkChanges = true;
    this.$emit('cancel');
  }

  /**
   * Event handler that saves the edition or the creation of user object
   */

  public onResetPassword() {
    this.resetPassword = !this.resetPassword;
  }

  /**
   * email and hasNoEmail shouldn't coexist, this prevents this
   */
  public onNoEmailCheckboxClick() {
    if (this.userCopy.userHasNoEmail) {
      this.userCopy.email = '';
    }
  }

  /**
   * suggestions for addresses in the Street combobox
   */
  private addressItems: any[] = [];

  /**
   * the String in the Address street Combobox for the OSM suggestions
   */
  private searchString: string = '';

  /**
   * is true when loading data from OpenStreetMaps
   */
  private isLoading: boolean = false;

  private searchBarDebounce: number = 0;

  /**
   * flag to skip the Load , which normally happens when changing the searchstring
   */
  private skipLoad: boolean = true;

  /**
   * Checking the user search input in the street field
   */
  @Watch('searchString')
  private async onSearchStreetChanged() {
    this.userCopy.address!.street = this.searchString;
    if (!this.skipLoad) {
      window.clearTimeout(this.searchBarDebounce);
      this.searchBarDebounce = window.setTimeout(async () => {
        await this.addressByStreet(this.searchString);
      }, 600);
    }
    this.skipLoad = false;
  }

  public getCity(address: any): string {
    if (address.city) {
      return address.city;
    } else if (address.town) {
      return address.town;
    } else if (address.village) {
      return address.village;
    } else {
      return address.suburb;
    }
  }

  /**
   * set the address of the location according to the selection
   */
  public setAddress(event: any) {
    this.searchString = event.address.road;
    this.userCopy.address!.street = event.address.road;
    this.userCopy.address!.postalCode = event.address.postcode;
    this.userCopy.address!.city = this.getCity(event.address);
  }

  /**
   * This function is called when the user changes the location Address via the combobox suggestions.
   * Changes the Address and updated the geolocation for the map
   * @param event the selected input from the combobox
   */
  public async searchInput(event: any) {
    if (typeof event === 'object') {
      this.skipLoad = true;
      // set address to location
      await this.setAddress(event);
      this.addressItems = [];
    }
  }

  /**
   * Searching the street, returning the address
   * @param street
   */
  private async addressByStreet(street: string): Promise<void> {
    if (!street) {
      return;
    }
    // Start loading
    this.isLoading = true;

    try {
      const response = await mapRepository.getAddress(street);
      this.addressItems = response.data;
    } catch (e) {
      this.$notifyErrorSimplified('LOCATION_MANAGE.NOTIFICATIONS.LOADING_MAP_ERROR');
    } finally {
      this.isLoading = false;
    }
  }

  public async onSubmit(event: Event) {
    event.preventDefault();
    const copyCleanTimes = this.availableAtCleanTimes;
    this.submitted = true;

    // Checks if edited user isn't a manager and if a value at maxWorkHours was entered
    // TODO: dont check workHours now
    /*if (this.isEmployee()) {
      if (this.userCopy.maxWorkHours === undefined || this.userCopy.maxWorkHours!.toString() === '') {
        this.maxHoursEmpty = true;
        return this.$notifyErrorSimplified('USER_MANAGE.NOTIFICATIONS.USER_MAXHOURSEMPTY');
      }
    }*/

    this.maxHoursEmpty = false;

    this.$v.userCopy!.$touch();

    if (this.$v.userCopy!.$invalid) {
      // if invalid scroll to first error input
      // OPTIMIZE the use of an css selector of a vuetify property is not always safe
      requestAnimationFrame(async () => await this.$vuetify.goTo('.error--text', {offset: 50}));
      this.$notifyErrorSimplified('GENERAL.MISSING_DATA');
    } else {
      // TODO: do not check workHours now but later
      /*if (this.isWorkingTimesInvalid()) {
        return this.$notifyErrorSimplified('USER_MANAGE.NOTIFICATIONS.WORK_TIMES_INVALID');
      }*/

      const isEdit = !!this.userCopy!.id;
      if (this.userCopy.personnelNumber === '') {
        this.userCopy.personnelNumber = null;
      }
      try {
        // Delete availableAtCleanTimes of userCopy or convert it to string array
        delete this.userCopy.availableAtCleanTimes;
        if (isEdit) {
          if (this.isManager() && `${this.userCopy.maxWorkHours!}` === '') {
            this.userCopy.maxWorkHours = null;
          }
          if (this.userCopy.plannedCleanTimes && this.userCopy.plannedCleanTimes.length > 0) {
            this.userCopy.plannedCleanTimes = (this.userCopy.plannedCleanTimes as CleanTime[]).map((cleanTime) => cleanTime.id!);
          }
          if (this.resetPassword && this.hasNoEmail()) {
            this.userCopy.oneTimePassword = this.oneTimePassword;
          }
          await this.editUserAction(this.userCopy);
        } else {
          this.userCopy!.companyId = this.$route.params.companyId;
          if (this.userCopy.userHasNoEmail && this.hasNoEmail()) {
            this.userCopy!.oneTimePassword = this.oneTimePassword;
          }
          const createdUser = await this.createUserAction(this.userCopy);
          this.userCopy.id = createdUser.id;

          // direct to user overview, after user was created
          if (this.gotoUsersOverview) {
            await this.$router.push({
              name: 'usersOverview',
              params: {
                companyId: this.$route.params.companyId,
              },
            });
          }
        }

        await this.handleCleanTimes(copyCleanTimes);

        this.$emit('exitModal', this.userCopy);
        if (isEdit) {
          this.$notifySuccessSimplified('USER_MANAGE.NOTIFICATIONS.USER_EDIT.SUCCESS');
        } else {
          this.$notifySuccessSimplified('USER_MANAGE.NOTIFICATIONS.USER_CREATE.SUCCESS');
        }
      } catch (e) {
        this.userCopy.oneTimePassword = '';
        this.userCopy.role = this.role!;
        if (e.data.key === 'PERSONNEL_NUMBER_IN_USE') {
          this.personalNumberInUse = true;
          this.$notifyErrorSimplified(`USER_MANAGE.NOTIFICATIONS.PERSONNEL_NUMBER_IN_USE`);
        } else if (this.$te(`USER_MANAGE.NOTIFICATIONS.${e.data.key}`)) {
          this.$notifyErrorSimplified(`USER_MANAGE.NOTIFICATIONS.${e.data.key}`);
        } else {
          if (isEdit) {
            this.$notifyErrorSimplified('USER_MANAGE.NOTIFICATIONS.USER_EDIT.ERROR');
          } else {
            this.$notifyErrorSimplified('USER_MANAGE.NOTIFICATIONS.USER_CREATE.ERROR');
          }
        }
      }
    }
  }

  /**
   * loads the roles when the popup is opened
   */
  public async mounted() {
    this.createPassword();
    try {
      await Promise.all([
        this.loadRolesAction({companyId: this.$route.params.companyId}),
        this.loadUsersAttributesAction(),
      ]);
      this.role = UserRole.parseFromObject(this.userCopy.role as UserRole);
    } catch (e) {
      this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.GENERAL_ERROR');
    }
  }

  /**
   * Status button event handler
   */
  private async onStatusButtonClick() {
    this.userCopy!.active = !this.userCopy!.active;
    try {
      await this.editUserAction(this.userCopy);
      if (this.userCopy!.active) {
        this.$notifySuccessSimplified('USER_MANAGE.NOTIFICATIONS.USER_ACTIVATE');
      } else {
        this.$notifySuccessSimplified('USER_MANAGE.NOTIFICATIONS.USER_DEACTIVATE');
      }
      this.$emit('exitModal', this.userCopy);
    } catch (e) {
      if (this.$te(`USER_MANAGE.NOTIFICATIONS.${e.data.key}`)) {
        this.$notifyErrorSimplified(`USER_MANAGE.NOTIFICATIONS.${e.data.key}`);
      } else {
        this.$notifyErrorSimplified('USER_MANAGE.NOTIFICATIONS.USER_EDIT.ERROR');
      }
    }
  }

  private setCleanTimesToDelete(deletedCleanTimes: CleanTime[]) {
    this.cleanTimesToDelete = deletedCleanTimes;
  }

  private setUSerRole(role: UserRole) {
    this.userCopy.role = role;
  }

  /**
   * This method deletes, updates and creates all cleanTimes
   * @param cleanTimes
   * @private
   */
  private async handleCleanTimes(cleanTimes: CleanTime[]) {
    // delete all cleanTimes with an id to avoid creating duplicates
    await Promise.all(this.cleanTimesToDelete.concat(cleanTimes).filter((cleanTime) => cleanTime.id)
      .map((cleanTime) => this.deleteCleanTimeAction(cleanTime.id!)));
    this.userCopy.availableAtCleanTimes = await Promise.all(cleanTimes
      .map((cleanTime) => {
        // delete ids of all cleanTimes and create new ones
        delete cleanTime.id;
        cleanTime.userId = this.userCopy.id;
        cleanTime.date = this.userCopy.firstDayInCompany || moment().toISOString();
        return this.createCleanTimeAction(cleanTime);
      }));
    this.availableAtCleanTimes = CleanTime.parseFromArray(this.userCopy.availableAtCleanTimes) as CleanTime[];
  }

  private setMaxWorkHours(value: number) {
    this.userCopy.maxWorkHours = value;
    this.maxHoursEmpty = value === 0;
  }

  private saveTimes(times: CleanTime[]) {
    this.availableAtCleanTimes = times;
  }

  private isWorkingTimesInvalid() {
    if (this.hasNoEmail() && this.invalidWorkingTimes) {
      return this.userCopy.availableAtCleanTimes!.some((cleanTime) => cleanTime.times.some((times) => !times.duration && times.duration === 0));
    }
    return false;
  }
}
